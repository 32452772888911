/* LIST PAGES ROUTES */
export const mainPageRoute = '/zaimy/:paramRegion?/';
export const naKartuPageRoute = '/zaimy/na-kartu/';
export const naKartuRegionPageRoute = '/zaimy/na-kartu/:paramRegion/';
export const onlinePageRoute = '/zaimy/mfo-online/';
export const newMicrocreditsPageRoute = '/zaimy/novinki/';
export const calculatorPageRoute = '/zaimy/kalkuljator/';

/* LANDING PAGES ROUTES */
export const landingPageRoute = '/zaimy/lushie_zaimy_61_dney_i_bolee/';
export const brandingPageRoute = '/zaimy/lushie_zaimy_61_dney_i_bolee_s_novim_mfo/';
export const promotionPageRoute = '/zaimy/akcii/';
export const partnersPageRoute = '/zaimy/partners/';
export const leadSuPageRoute = '/zaimy/lushie_zaimy_bistro/';
export const guruleadsPageRoute = '/zaimy/lushie_zaimy_bystro/';
export const specOffersLandingPageRoute = '/zaimy/special/';

export const specOffersLandingPageRouteWithSpecificAffId = '/zaimy/special/affho/:affId';
export const specOffersLandingPageRouteWithSpecificAffIdAndAffSub2 =
    '/zaimy/special/affho/:aff_id/hub/:aff_sub2([0-9]+[0-9\\.]*)';

/* SPECIAL MFO ROUTES */
export const specOffersLandingPageRouteWebbankirWb1 = '/zaimy/special/webbankir/wb1/';
export const specOffersLandingPageRouteWebbankirWb2 = '/zaimy/special/webbankir/wb2/';
export const specOffersLandingPageRouteBistrodengi = '/zaimy/special/bistrodengi/';
export const specOffersLandingPageRouteTurbozaim = '/zaimy/special/turbozaim/';

export const specialMfoRoutes = [
    specOffersLandingPageRouteWebbankirWb1,
    specOffersLandingPageRouteWebbankirWb2,
    specOffersLandingPageRouteBistrodengi,
    specOffersLandingPageRouteTurbozaim,
] as const;

/* PRODUCT PAGES */
export const productPageRoute = '/zaimy/mfo/:organizationAlias/mikrokredit/:productAlias/';
export const oldProductPageRoute = '/mikrofinansovaja-organizacija/:organizationAlias/mikrokredit/:productAlias/';
export const productDetailsRoute = '/zaimy/mfo/:organizationAlias/:productAlias/';

/* MICROCREDIT SELECTION ROUTES */
export const microcreditSelectionRoute = '/zaimy/onlain/';

/* API ROUTES */
export const getIndexedLocationsRoute = '/metadata/index';
export const getRegionalCentersRoute = '/location/centers';
export const getRegionsRoute = '/location/regions';
export const getUserCode = '/signing/code/';
export const getUserVerify = '/signing/verify/';
export const webVitalsRoute = '/web/vitals';
export const saveUserPhoneRoute = '/save-phone';

/* INTERNAL ROUTES */
export const testCheckRoute = '/zaimy/test';
export const bundleAnalyzeRoute = '/internal/bundle-analyze';

/* ROUTE FOR ANDROID WEB VIEW */
export const whiteLabelRoute = '/zaimy/wl_app/';

export const promoTermsRoute = '/zaimy/promo-terms/';
export const appLandingRoute = '/zaimy/app/';

export const whiteLabelDeniedRoute = '/zaimy/wl_app/other/';

export const pilotKZPageRoute = '/kz/zaimy/';

export const mfoAndroidRoute = '/promo/mfo_android/';

/* Pod PTS ROUTES */
export const podPtsListRoutes: readonly string[] = [
    '/zaimy/pod-pts/',
    '/zaimy/pod-pts/:paramRegion/',
    '/zaimy/pod-pts-avtolombardy/',
    '/zaimy/pod-pts-avtolombardy/:paramRegion/',
    '/zaimy/pod-pts-bez-avto/',
    '/zaimy/pod-pts-bez-avto/:paramRegion/',
    '/zaimy/bez-pts/',
    '/zaimy/pod-pts-po-doverennosti/',
    '/zaimy/pod-zalog-pts-jur-licam/',
];

/* SEO ROUTES */
export const seoListRoutes: readonly string[] = [
    '/zaimy/1000-na-kivi/',
    '/zaimy/2000-na-kivi/',
    '/zaimy/24-chasa-onlayn/',
    '/zaimy/300-na-kivi/',
    '/zaimy/3000-na-kivi/',
    '/zaimy/500-na-kivi/',
    '/zaimy/5000-na-kivi/',
    '/zaimy/50000-na-kivi/',
    '/zaimy/70-let/',
    '/zaimy/75-let/',
    '/zaimy/80-let/',
    '/zaimy/85-let/',
    '/zaimy/besplatno/',
    '/zaimy/bez-documentov/',
    '/zaimy/bez-elektronnoj-pochty/',
    '/zaimy/bez-foto/',
    '/zaimy/bez-karty/',
    '/zaimy/bez-kreditnoy-istorii/',
    '/zaimy/bez-obmana/',
    '/zaimy/bez-otkaza-100-procentov-odobreniya/',
    '/zaimy/bez-otkaza/',
    '/zaimy/bez-pasporta/',
    '/zaimy/bez-podpisok/',
    '/zaimy/bez-podtverzhdenija-lichnosti/',
    '/zaimy/bez-poruchiteley-i-spravok/',
    '/zaimy/bez-posrednikov/',
    '/zaimy/bez-predoplaty/',
    '/zaimy/bez-propiski-i-vremennoy-registracii/',
    '/zaimy/bez-protsentov-na-30-dnej/',
    '/zaimy/bez-protsentov/',
    '/zaimy/bez-proverok/',
    '/zaimy/bez-snilsa/',
    '/zaimy/bez-spisanija/',
    '/zaimy/bez-spravok-o-dohodah/',
    '/zaimy/bez-telefona/',
    '/zaimy/bezotkazanye/',
    '/zaimy/bezrabotnym-na-kivi/',
    '/zaimy/bezrabotnym-s-plohoi-ki/',
    '/zaimy/bezrabotnym/',
    '/zaimy/bolshie-zaimy/',
    '/zaimy/cherez-gosuslugi/',
    '/zaimy/dengi-v-dolg-bez-otkaza/',
    '/zaimy/dengi-v-dolg-bez-proverki-ki/',
    '/zaimy/dengi-v-dolg-na-kivi/',
    '/zaimy/dengi-v-dolg-v-20-let/',
    '/zaimy/dengi-v-rassrochku-bez-procentov/',
    '/zaimy/dlja-ip/',
    '/zaimy/dlja-pogashenija/',
    '/zaimy/dlya-grazhdan-sng/',
    '/zaimy/dolg-na-kartu-sberbanka/',
    '/zaimy/dolgosrochnye/',
    '/zaimy/dolzhnikam/',
    '/zaimy/ehkspress-na-kartu/',
    '/zaimy/inostrancam/',
    '/zaimy/juridicheskim-licam/',
    '/zaimy/kalkuljator-microzaimov/',
    '/zaimy/kivi-yandex/',
    '/zaimy/kratkosrochnye/',
    '/zaimy/kruglosutochno/',
    '/zaimy/legkie/',
    '/zaimy/maloizvestnye-mfo/',
    '/zaimy/mfo-bez-proverki-kreditnoy-istorii/',
    '/zaimy/mgnovennye/',
    '/zaimy/mikro/',
    '/zaimy/mikrozaymy-dlya-biznesa/',
    '/zaimy/mini-onlajn/',
    '/zaimy/mkk/',
    '/zaimy/momentalny/',
    '/zaimy/na-1-mesjac/',
    '/zaimy/na-10-dnej/',
    '/zaimy/na-2-goda/',
    '/zaimy/na-2-mesjaca/',
    '/zaimy/na-3-mesjaca/',
    '/zaimy/na-4-mesjaca/',
    '/zaimy/na-60-dnej/',
    '/zaimy/na-bankovskiy-schet/',
    '/zaimy/na-chuzhuju-kartu/',
    '/zaimy/na-contact/',
    '/zaimy/na-debetovuju-kartu/',
    '/zaimy/na-dom/',
    '/zaimy/na-elektronnyy-koshelek/',
    '/zaimy/na-god-12-mesyacev/',
    '/zaimy/na-kartu-bez-otkazov/',
    '/zaimy/na-kartu-bez-proverki-kreditnoy-istorii/',
    '/zaimy/na-kartu-bez-proverok-i-zvonkov/',
    '/zaimy/na-kartu-do-zarplaty/',
    '/zaimy/na-kartu-kukuruza-online/',
    '/zaimy/na-kartu-maestro/',
    '/zaimy/na-kartu-mastercard/',
    '/zaimy/na-kartu-mgnovenno-kruglosutochno/',
    '/zaimy/na-kartu-mgnovenno-pod-0-procentov/',
    '/zaimy/na-kartu-mir/',
    '/zaimy/na-kartu-momentum/',
    '/zaimy/na-kartu-s-18-let-online/',
    '/zaimy/na-kartu-s-nulevym-balansom/',
    '/zaimy/na-kartu-sberbanka-bezotkazno/',
    '/zaimy/na-kartu-sberbanka-do-zarplaty/',
    '/zaimy/na-kartu-sberbanka/',
    '/zaimy/na-kartu-tinkoff/',
    '/zaimy/na-kartu-visa/',
    '/zaimy/na-kartu-vtb/',
    '/zaimy/na-kartu-za-5-minut/',
    '/zaimy/na-kivi-bez-karty/',
    '/zaimy/na-kivi-bez-otkazov/',
    '/zaimy/na-kivi-bez-pasporta/',
    '/zaimy/na-kivi-bez-procentov/',
    '/zaimy/na-kivi-bez-zvonkov/',
    '/zaimy/na-kivi-pensioneram/',
    '/zaimy/na-kivi-po-pasportu/',
    '/zaimy/na-kivi-s-18-let/',
    '/zaimy/na-kivi-s-plohoy-ki/',
    '/zaimy/na-kivi-virtualnuju-kartu/',
    '/zaimy/na-kivi/',
    '/zaimy/na-kreditnuju-kartu/',
    '/zaimy/na-neimennuyu-kartu/',
    '/zaimy/na-polgoda-6-mesyacev/',
    '/zaimy/na-sberknizhku/',
    '/zaimy/na-telefon/',
    '/zaimy/na-virtualnuyu-kartu/',
    '/zaimy/na-yandex-bez-privjazki-karty/',
    '/zaimy/na-yandex/',
    '/zaimy/na-zolotuyu-koronu/',
    '/zaimy/nalichnymi/',
    '/zaimy/ne-vyhodja-iz-doma/',
    '/zaimy/nochju/',
    '/zaimy/novye-mfo/',
    '/zaimy/novye/',
    '/zaimy/pensioneram/',
    '/zaimy/perevodom/',
    '/zaimy/po-pasportu/',
    '/zaimy/po-sms/',
    '/zaimy/po-telefonu/',
    '/zaimy/pod-0-protsentov/',
    '/zaimy/pod-nizkij-procent/',
    '/zaimy/pod-zalog-avtomobilya-s-pravom-polzovaniya/',
    '/zaimy/pod-zalog-documentov/',
    '/zaimy/pod-zalog-gruzovogo-avtomobilja/',
    '/zaimy/pod-zalog-motocykla/',
    '/zaimy/pod-zalog-nedvizhimosti/',
    '/zaimy/pod-zalog-spectehniki/',
    '/zaimy/refinansirovanie/',
    '/zaimy/robot/',
    '/zaimy/s-19-let-na-kartu/',
    '/zaimy/s-21-goda/',
    '/zaimy/s-avtomaticheskim-odobreniem-na-kartu-online/',
    '/zaimy/s-chernym-spiskom/',
    '/zaimy/s-ezhemesyachnym-platezhom/',
    '/zaimy/s-ljuboy-ki/',
    '/zaimy/s-plokhoj-kreditnoj-istoriej/',
    '/zaimy/s-prosrochkami/',
    '/zaimy/servis/',
    '/zaimy/srochno-100/',
    '/zaimy/srochno-1000/',
    '/zaimy/srochno-10000/',
    '/zaimy/srochno-100000/',
    '/zaimy/srochno-1000000/',
    '/zaimy/srochno-12000/',
    '/zaimy/srochno-1500/',
    '/zaimy/srochno-15000/',
    '/zaimy/srochno-150000/',
    '/zaimy/srochno-2000/',
    '/zaimy/srochno-20000/',
    '/zaimy/srochno-200000/',
    '/zaimy/srochno-25000/',
    '/zaimy/srochno-250000/',
    '/zaimy/srochno-3000/',
    '/zaimy/srochno-30000/',
    '/zaimy/srochno-300000/',
    '/zaimy/srochno-35000/',
    '/zaimy/srochno-4000/',
    '/zaimy/srochno-40000/',
    '/zaimy/srochno-500/',
    '/zaimy/srochno-5000/',
    '/zaimy/srochno-50000/',
    '/zaimy/srochno-500000/',
    '/zaimy/srochno-6000/',
    '/zaimy/srochno-60000/',
    '/zaimy/srochno-7000/',
    '/zaimy/srochno-70000/',
    '/zaimy/srochno-8000/',
    '/zaimy/srochno-80000/',
    '/zaimy/srochno-9000/',
    '/zaimy/srochno-90000/',
    '/zaimy/studentam-na-kartu-bez-otkaza/',
    '/zaimy/top/',
    '/zaimy/uluchshenie-ki/',
    '/zaimy/v-dolg-na-kartu/',
    '/zaimy/v-dolg-na-yandex/',
    '/zaimy/v-dolg/',
    '/zaimy/voennosluzhashhim/',
    '/zaimy/vse-produkty/',
    '/zaimy/vsem/',
    '/zaimy/za-15-minut/',
    '/zaimy/za-chas/',
    '/zaimy/za-minutu/',
    '/zaimy/zayavka-vo-vse-mikrozaimy-srazu/',
    '/zaimy/pervyj-bez-protsentov/',
    '/zaimy/na-yumoney/',
    '/zaimy/bez-poseshcheniya-ofisa/',
    '/zaimy/online/',
    '/zaimy/onlain/',
    '/zaimy/srazu/',
    '/zaimy/pod-raspisku/',
    '/zaimy/bez-zaloga/',
    '/zaimy/bez-ukazanija-raboti/',
    '/zaimy/na-5-let/',
    '/zaimy/po-vsej-rossii/',
    '/zaimy/bystriy-na-kartu/',
    '/zaimy/mikrokredit-na-kartu-onlayn/',
    '/zaimy/na-noviy-god/',
    '/zaimy/proverennyye-zaymy-do-30-000/',
];

export const seoListRegionRoutes: readonly string[] = [
    '/zaimy/24-chasa-onlayn/:paramRegion/',
    '/zaimy/besplatno/:paramRegion/',
    '/zaimy/bez-foto/:paramRegion/',
    '/zaimy/bez-karty/:paramRegion/',
    '/zaimy/bez-otkaza-100-procentov-odobreniya/:paramRegion/',
    '/zaimy/bez-otkaza/:paramRegion/',
    '/zaimy/bez-pasporta/:paramRegion/',
    '/zaimy/bez-propiski-i-vremennoy-registracii/:paramRegion/',
    '/zaimy/bez-protsentov/:paramRegion/',
    '/zaimy/bez-proverok/:paramRegion/',
    '/zaimy/bezrabotnym/:paramRegion/',
    '/zaimy/dengi-v-dolg-na-kivi/:paramRegion/',
    '/zaimy/dolgosrochnye/:paramRegion/',
    '/zaimy/ehkspress-na-kartu/:paramRegion/',
    '/zaimy/kruglosutochno/:paramRegion/',
    '/zaimy/maloizvestnye-mfo/:paramRegion/',
    '/zaimy/mikro/:paramRegion/',
    '/zaimy/mikrozaymy-dlya-biznesa/:paramRegion/',
    '/zaimy/mini-onlajn/:paramRegion/',
    '/zaimy/momentalny/:paramRegion/',
    '/zaimy/na-1-mesjac/:paramRegion/',
    '/zaimy/na-bankovskiy-schet/:paramRegion/',
    '/zaimy/na-contact/:paramRegion/',
    '/zaimy/na-dom/:paramRegion/',
    '/zaimy/na-elektronnyy-koshelek/:paramRegion/',
    '/zaimy/na-god-12-mesyacev/:paramRegion/',
    '/zaimy/na-kartu-bez-otkazov/:paramRegion/',
    '/zaimy/na-kartu-bez-proverki-kreditnoy-istorii/:paramRegion/',
    '/zaimy/na-kartu-bez-proverok-i-zvonkov/:paramRegion/',
    '/zaimy/na-kartu-do-zarplaty/:paramRegion/',
    '/zaimy/na-kartu-maestro/:paramRegion/',
    '/zaimy/na-kartu-mgnovenno-kruglosutochno/:paramRegion/',
    '/zaimy/na-kartu-mir/:paramRegion/',
    '/zaimy/na-kartu-s-18-let-online/:paramRegion/',
    '/zaimy/na-kartu-sberbanka/:paramRegion/',
    '/zaimy/na-kartu-za-5-minut/:paramRegion/',
    '/zaimy/na-kivi-bez-karty/:paramRegion/',
    '/zaimy/na-kivi-bez-otkazov/:paramRegion/',
    '/zaimy/na-kivi/:paramRegion/',
    '/zaimy/na-polgoda-6-mesyacev/:paramRegion/',
    '/zaimy/na-yandex/:paramRegion/',
    '/zaimy/na-zolotuyu-koronu/:paramRegion/',
    '/zaimy/nalichnymi/:paramRegion/',
    '/zaimy/novye/:paramRegion/',
    '/zaimy/pensioneram/:paramRegion/',
    '/zaimy/po-pasportu/:paramRegion/',
    '/zaimy/po-telefonu/:paramRegion/',
    '/zaimy/pod-0-protsentov/:paramRegion/',
    '/zaimy/pod-nizkij-procent/:paramRegion/',
    '/zaimy/pod-zalog-gruzovogo-avtomobilja/:paramRegion/',
    '/zaimy/s-19-let-na-kartu/:paramRegion/',
    '/zaimy/s-ezhemesyachnym-platezhom/:paramRegion/',
    '/zaimy/s-plokhoj-kreditnoj-istoriej/:paramRegion/',
    '/zaimy/s-prosrochkami/:paramRegion/',
    '/zaimy/srochno-1000/:paramRegion/',
    '/zaimy/srochno-10000/:paramRegion/',
    '/zaimy/srochno-100000/:paramRegion/',
    '/zaimy/srochno-15000/:paramRegion/',
    '/zaimy/srochno-2000/:paramRegion/',
    '/zaimy/srochno-20000/:paramRegion/',
    '/zaimy/srochno-3000/:paramRegion/',
    '/zaimy/srochno-30000/:paramRegion/',
    '/zaimy/srochno-40000/:paramRegion/',
    '/zaimy/srochno-5000/:paramRegion/',
    '/zaimy/srochno-50000/:paramRegion/',
    '/zaimy/srochno-60000/:paramRegion/',
    '/zaimy/srochno-70000/:paramRegion/',
    '/zaimy/srochno-80000/:paramRegion/',
    '/zaimy/studentam-na-kartu-bez-otkaza/:paramRegion/',
    '/zaimy/top/:paramRegion/',
    '/zaimy/v-dolg-na-kartu/:paramRegion/',
    '/zaimy/v-dolg/:paramRegion/',
    '/zaimy/online/:paramRegion/',
];

/* SELECT REGION PAGE ROUTES (вынесены из seoListRoutes) */
const selectRegionListRoutes: readonly string[] = [
    '/zaimy/vybor-regiona/',
    '/zaimy/pod-pts/vybor-regiona/',
    '/zaimy/na-kartu/vybor-regiona/',
];

export const seoSelectRegionRoutes = seoListRoutes
    .map((route) => `${route}vybor-regiona/`)
    .concat(selectRegionListRoutes);

export const generalRouteNamesMap = new Map([
    [seoListRoutes, 'seo_list_routes'],
    [seoListRegionRoutes, 'seo_list_region_routes'],
    [seoSelectRegionRoutes, 'seo_select_region_routes'],
    [podPtsListRoutes, 'pod_pts_list_routes'],
]);
